import React from 'react'
import './reviews.css'
import fivestar from '../../assets/fivestar.png'

export default function Reviews() {
  return (
    <div className="reviews-container">
        <h2 className='reviews-title'>Parents Love Us</h2>
        <div className="reviews-list">
            <div className="review">
                <h3>Chanel V.</h3>
                <p>Great School! Awesome Teachers! Great Staff! My daughter has been going here since she was 10 months old when they had and infant room. They have always treated her with love and kindness. She loves her school and her father and I do too. It is super clean, well maintained, staffing standards are next level. Just an overall awesome, well run establishment. Highly recommend.</p>
                <img src={fivestar} alt="Five stars" />
            </div>
            <div className="review">
                <h3>Nicole S.</h3>
                <p>Signed my daughter up at the end of August this year when she turned one. Me and my husband have seen a huge improvement in her growth since she started here. They are great at communication and keep me informed about everything. They have a healthy hot lunch program, yoga, spanish, sign language, music time, etc. I highly recommend this school.</p>
                <img src={fivestar} alt="Five stars" />
            </div>
            <div className="review">
                <h3>Lacey T.</h3>
                <p>Love this school! Really glad I enrolled both my boys here! Its such a great atmosphere! The kids love going everyday, and the growth displayed has been incredible! The teachers are really great with communication and the Director Peggy is so kind and personable! She keeps us informed with pictures of daily activities and weekly learning plans!</p>
                <img src={fivestar} alt="Five stars" />
            </div>
        </div>
    </div>
  )
}
