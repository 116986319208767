import React from 'react'
import './footer.css'
import logo from '../../assets/LIM_STACKEDV1.png'
import NAClogo from '../../assets/NAC Logo.png'
import goalseal from '../../assets/goldseal.png'
import schoolreadiness from '../../assets/SchoolReadiness.jpg'

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-list">
        <div className="footer">
          <h3>Our Location</h3>
          <h4>6820 NW 70th Ave, Tamarac, FL, 33321</h4>
          <h4>School Hours: 6:30am - 6:30pm</h4>
          <div className='footer-map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1064.2408061738806!2d-80.24537356927368!3d26.20756996887327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d905b8485f5b01%3A0x7366d88ba84cd55b!2sLearning%20In%20Motion%20Academy!5e0!3m2!1sen!2sus!4v1689363192423!5m2!1sen!2sus" 
            title="footer map"
            className="googlemap"
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
        <div className="footer">
          <div className='footer-logos'>
            <img className='footer-logo' src={logo} alt="Learning In Motion Logo"/>
            <img className='nac-logo' src={NAClogo} alt="Asssociation For Early Learning Leaders"/>
            <img className='school-readiness' src={schoolreadiness} alt="School Readiness"/>
            <img className='goalseal-logo' src={goalseal} alt="Florida Quality Care Gold Seal" />
          </div>
        </div>
        <div className="footer">
          <h3>Contact Us</h3>
          <h4 className='footer-number'>954 722-4880</h4>
          <h4>Director, Ms. Peggy</h4>
          <h4>Peggy@LIMAcademy.com</h4>
        </div>
      </div>
      <h4 className="copyright">Learning In Motion Academy, Inc. ©</h4>
    </div>
  )
}
