import React, { useState } from 'react'
import { Link } from "react-router-dom";
import './navbar.css'
import logo from '../../assets/ACADMEY-12.png'

export default function Header() {

// ====== Hamburger Toggle ===== //
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  }

  return (
    <div className="navbar">
        <Link to='/'>
            <img src={logo} alt="Learning In Motion Academy" />
        </Link>
        <nav>
            <ul className={`nav-menu ${isActive ? 'active' : ''}`}>
                <li>
                    <Link to='/'>Home</Link>
                </li>
                <li>
                    <Link to='/curriculum'>Curriculum</Link>
                </li>
                <li>
                    <Link to='/about'>About</Link>
                </li>
                <li>
                    <Link to='/tution'>Tuition</Link>
                </li>
                <li>
                    <Link to='/contact' className='nav-menu__contact'>Contact Us</Link>
                </li>
            </ul>

            <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={handleToggle}>
                <span className='bar'></span>
                <span className='bar'></span>
                <span className='bar'></span>
            </div>
        </nav>
    </div>
  )
}
