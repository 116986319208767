import React from 'react'
import { Link } from "react-router-dom";
import './mission.css'
import penelope_gif from '../../assets/Logo Waving.gif'
import boy_6 from '../../assets/boy_6.JPG'


export default function Mission() {
  return (
    <div className='mission_container'>
      <div className='mission-grid'>
        <div className='mission-item'>
          <img className='mission-img one' src={boy_6} alt="Little boy doing puzzle"></img>
          <img src={penelope_gif} alt="Penelope waving" className="mission-gif" />
        </div>
        <div className='mission-item'>
          <h1 className="mission-title">Our Mission</h1>
          <h2 className="mission-text">We are a specialty school that offers classes for children from 1 year old through pre-kindergarten ages. Our curriculum is a unique blend of Montessori and Traditional teaching methods which helps develop successful, well rounded children. Our students receive the best of both educational techniques and equipment; offered at a fair and reasonable tuition. We are excited to offer such a unique program to this community. We call it <i>Learning in Motion</i>.</h2>
          <Link to='/about' className="learnmore-btn">Learn More</Link>
        </div>
      </div>
    </div>
  )
}
