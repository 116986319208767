import React from 'react'
import './contact.css'

export default function Contact() {
  return (
    <div className="contact-container">
      <div className='contact-list'>
        <div className='contact-info'>
        <h2>Contact Us</h2>
        <h3 className='contact-number'>954 722-4880</h3>
        <h3>Director, Ms. Peggy</h3>
        <h3>Peggy@LIMAcademy.com</h3>
        <h2>Our Location</h2>
        <h3>6820 NW 70th Ave, Tamarac, FL, 33321</h3>
        <h3>School Hours: 6:30am - 6:30pm</h3>
        </div>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1064.2408061738806!2d-80.24537356927368!3d26.20756996887327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d905b8485f5b01%3A0x7366d88ba84cd55b!2sLearning%20In%20Motion%20Academy!5e0!3m2!1sen!2sus!4v1689363192423!5m2!1sen!2sus" 
          title="contact map"
          className="googlemap"
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  )
}
