import React from 'react'
import './tution.css'

export default function Tution() {
  return (
    <div className="tution-container">
        <div className='tution-list'>
            <div className='tution'>
                <h3 className='wt'>Wobbler & Toddler</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$215</h2>
                        <h2>$180</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$930</h2>
                        <h2>$780</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='twoyr'>2 Years Old</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$200</h2>
                        <h2>$170</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$865</h2>
                        <h2>$735</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='threetofouryr'>3 to 4 Years Old</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>3 Days</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>$190</h2>
                        <h2>$160</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>$820</h2>
                        <h2>$690</h2>
                    </div>
                </div>
            </div>
            <div className='tution'>
                <h3 className='vpk-ext'>VPK</h3>
                <div className='rate-grid'>
                    <div className='rate'>
                        <h2 className='rate-heading'>Days per Week</h2>
                        <h2>5 Days</h2>
                        <h2>VPK Extended Care</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Weekly Payment</h2>
                        <h2>FREE</h2>
                        <h2>$145</h2>
                    </div>
                    <div className='rate'>
                        <h2 className='rate-heading'>Monthly Payment</h2>
                        <h2>FREE</h2>
                        <h2>$630</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='inital'>
            <h3>Initial and Annual Registration Fee - $150</h3>
            <h3>Summer Activity fee - $70</h3>
            <h3>Cot Sheet Fee - $20</h3>
            <h3>Catered Lunch - $4.50 M-Th</h3>
            <h3>Pizza Friday - $5</h3>
            <h3><em>Rates effective August 2024. Rates subject to change. Contact the Director for current rates.</em></h3>
        </div>
    </div>
  )
}
